import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Greating02 from '../../components/greating02/greating02';
import Greating from '../../components/greating/greating';
import CarouselB from '../../components/carouselb/carouselb';
import './home.scss'

function HomePage() {
    return (
        <Row>
            <Col md={5}>
                <Row>
                    <Col>
                        <Greating></Greating>
                    </Col>
                </Row>
                <Row>
                    <Greating02></Greating02>
                </Row>
            </Col>
            <Col md={7}>
                <CarouselB></CarouselB>
            </Col>
        </Row>
    )
}

export default HomePage;