/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import checkboxicon from "../icons/checkbox.svg";
import './saverenderer.scss'
// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const { data, context, api, node } = props;

    if (!data || !context) {
        console.warn("⚠️ SaveRenderer2: context vagy data hiányzik!", { data, context });
        return null;
    }

    const isEditing = context?.editingRowId === data.ID;

    const buttonClicked = () => {
        if (api && node) {
            api.stopEditing();
        }
        console.log("✅ Mentés gomb megnyomva!", data);
    };

    // 🔥 Ha a gomb fókuszban van és Entert nyomnak, kattintás szimulálása
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            buttonClicked();
        }
    };

    const onFocus = (event) => {
        console.log('!!!SSS saveRenderer2.js (line: 32)');
    };

    return isEditing ? (
        // eslint-disable-next-line jsx-a11y/no-redundant-roles
        <button
            role="button"
            className="pencilIcon"
            style={{ cursor: 'pointer', display: 'inline-block' }}
            onClick={buttonClicked}
            onKeyDown={handleKeyDown}
            tabIndex={0} // 🔥 Ettől fókuszálható az elem
            onFocus={onFocus}
        >
            <img
                src={checkboxicon}
            >
            </img>
        </button>
    ) : null;

    // return isEditing ? (
    //     <img
    //         className='pencilIcon'
    //         src={checkboxicon}
    //         onClick={buttonClicked}
    //         onKeyDown={handleKeyDown}
    //         tabIndex={0}
    //         alt="Save"
    //     />
    // ) : null;
};
