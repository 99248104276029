import axios from "axios";
const DataURL = `${process.env.REACT_APP_API_BASE_URL}/data`;

async function DelRow2(props) {
    const selectedData = props.gridRef.current.api.getSelectedRows();
    const deletedIds = JSON.stringify(selectedData.map(({ ID }) => ({ ID })));
    return axios.delete(DataURL, {
        params: {
            collection: props.collection,
        },
        headers: {
            data: deletedIds,
            token: props.token,
            userName: props.userName,
        },
    }).then(async (result) => {
        return result;
    }).catch((err) => {
        console.error(err);
    })
}

export default DelRow2;