import Image from 'react-bootstrap/Image';
import fudo from '../../pictures/fudodachi_3_2.png'

function Car03() {
    return (
        <div>
            <Image src={fudo} fluid />
        </div>
    )
}

export default Car03;