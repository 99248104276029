import { useState } from "react";
import { Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SaveData from "../../components/datahandling/savedata";
import ShowData from "../../components/datahandling/showdata";
function FormExercise(props) {
    const [exerciseDescription, setExerciseDescription] = useState('')
    const [exerciseName, setExerciseName] = useState('')
    const [gridDataRow, setGridDataRow] = useState('')
    const myCollection = 'vExercise';

    function CloseMe() {
        props.setFormState('HBASE')

    }

    function updateExerciseDescription(fieldValue) {
        setExerciseDescription(fieldValue);
        var myRowData = gridDataRow;
        myRowData.ExerciseDescription = fieldValue;
        setGridDataRow(myRowData);
    }

    function setDescription(myData) {
        if (myData) {
            setGridDataRow(myData);
            setExerciseDescription(myData.ExerciseDescription);
            setExerciseName(myData.ExerciseName);
        }
    }

    async function RefreshGridData() {
        await ShowData({
            token: props.token,
            setRowData: props.setExerciseRowData,
            language: 'HU',
            userName: props.userName,
            collection: myCollection,
            where: '',
        });
    }


    async function FormSaveData() {
        await SaveData({
            token: props.token,
            language: 'HU',
            userName: props.userName,
            collection: myCollection,
            saveprops: gridDataRow,
        })
            .then(async (result) => {
                await RefreshGridData();
                CloseMe();
                return;
            })
            .catch((err) => {
                alert(err.response.data.message);
                console.error(err);
            });
    }

    props.biRef.SetFormFields_f = setDescription;

    return (
        <div>
            <Form.Group className="mb-3" controlId="ExerciseDescription">
                <Form.Label>{exerciseName} gyakorlat leírása</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={(e) => updateExerciseDescription(e.target.value)}
                    // onFocus={onDesFocus}
                    value={exerciseDescription}
                />
            </Form.Group>
            <Row className="formbtns">
                <Col md={1}>
                    <Button variant="primary"
                        id="formbtnsave"
                        onClick={() => FormSaveData()}
                        >
                        Adat mentése
                    </Button>
                </Col>
                <Col md={1}>
                    <Button variant="secondary"
                        id="formbtncancel"
                        onClick={() => CloseMe()}
                    >
                        Mégsem
                    </Button>
                </Col>
                <Col md={10}></Col>

            </Row>
        </div>
    )
}

export default FormExercise;