import { AgGridReact } from "ag-grid-react"
import { useState, useRef, useCallback } from "react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import "./pageTest.scss"

function Teszt(props) {
    const [gridData, setGridData] = useState([]);

    const DataUrl = `${process.env.REACT_APP_API_BASE_URL}/data`;
    const gridRef = useRef();

    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        { field: "ID" },
        { field: "PersonName" },
        { field: "BirthDay" },
        { field: "EmailAddress" },
        { field: "Gender" },
        { field: "ClubID" },
    ]);

    function showData() {
        fetch(DataUrl, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                language: props.language,
                'token': props.token,
                'collection': 'persons'
            },
        })
            .then((data) => {
                if (data.status !== 200) {
                    const error = new Error('invalid');
                    error.status = data.status;
                    throw error;
                }
                return data.json();
            })
            .then((jsonData) => {
                setGridData(jsonData.data)

            })
            .catch(() => {
            });
    }

    return (
        <div
            className="ag-theme-quartz-dark" // applying the Data Grid theme
            style={{ height: 500 }} // the Data Grid will fill the size of the parent container
        >
            <AgGridReact
                rowData={gridData}
                columnDefs={colDefs}
                gridRef={gridRef}
                onGridReady={showData}
            />
        </div>
    )
}

export default Teszt