import Image from 'react-bootstrap/Image';
import pict from '../../pictures/goteborg_2023_04_02.jpg'

function Caro2() {
    return (
        <div>
            <Image src={pict} fluid />
        </div>
    )
}

export default Caro2;