import Image from 'react-bootstrap/Image';
import griff23 from '../../pictures/Griff_Adoszam.png'

function Car01() {
    return (
        <div>
            <Image src={griff23} fluid />
        </div>
    )
}

export default Car01;