import { Col, Row } from "react-bootstrap"
import Pict from '../../../pictures/Josportolo.png'
import Image from 'react-bootstrap/Image';
import Eredm from '../2025/pictures/Josportolo_Eredmenyek.png'

function Event2025_02_08() {
    return (
        <div className="MsoNormal">
            <Row className="headline">
                <Col md={6}>
                    <h2>Jó sportoló elismerés</h2>
                </Col>
                <Col md={6}>
                    <h2>2025.02.08</h2>
                </Col>
            </Row>
            <br></br>
            <Row md={4}>
                <Col>
                    <p>
                        <h5>
                            Pápa Város Önkormányzata az idén is elismerésben részesítette a 2024-es év legeredményesebb utánpótlás és felnőtt sportolóit, valamint felkészítőit. A Griff Sportegyesület versenyzői évtizedek óta rendszeres meghívott vendégei ennek a rendezvénynek, ahol a világ-, európai- és országos versenyeken 1-6 helyezést elért sportolókat díjazzák.
                            A 2024-es évben 6 olyan versenyzőnk volt, akik elismerésben részesültek.
                        </h5>
                    </p>
                    <Image src={Eredm} fluid />
                    <br></br>
                    <br></br>
                    <h5>
                        <p>Sportágunkban hazánkban és nemzetközi szinten is nagy a rivalizálás, népes mezőnyben kell helyt állnia az indulóknak, ezért nagyon meg kell dolgozniuk egy-egy helyezésért.
                            Külön köszönet illeti edzőinket, akik lelkiismeretes munkával segítették karatésainkat ezen a szép, de cseppet sem könnyű úton.
                        </p>
                    </h5>
                </Col>
                <Col>
                    <p>
                        <h4>
                            Edzőink:
                        </h4>
                    </p>
                    <p>
                        <h4>
                            <li>
                                Sinkó Attila<br></br>
                            </li>
                            <li>
                                Mógor Tibor<br></br>
                            </li>
                            <li>
                                Balogh László<br></br>
                            </li>
                            <li>
                                Szalóki Ildikó<br></br>
                            </li>
                            <li>
                                Petrovics Ádám<br></br>
                            </li>
                            <li>
                                Szalóki Sándor
                            </li>
                        </h4>
                    </p>
                    <p>
                        <h5>
                            <br></br>
                            <br></br>
                            <br></br>
                            Köszönetünket szeretnénk kifejezni a szülőknek is, akik támogatják munkánkat, időt, energiát nem sajnálva segítik gyermeküket. Megfelelő szülői háttér nélkül hosszú távon elképzelhetetlenek a versenyzői eredmények.
                        </h5>
                    </p>
                    <br></br>
                    <br></br>
                    <br></br>
                    <p>
                        <h4>
                            Bízunk benne, hogy az elért sikerek további motivációt biztosítanak taníványainknak!
                        </h4>
                    </p>
                </Col>
                <Col>
                    <Image src={Pict} fluid />
                    <br></br>
                    <br></br>
                    <a href="https://papaimediacentrum.hu/A_varosnak_a_sportban_is_vannak_kincsei/" target="_blank" rel="noopener noreferrer">
                        Olvasd el a Pápai Média Centrum írását is
                    </a>
                </Col>
            </Row>
        </div>
    )

}
export default Event2025_02_08