import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import myLogo from '../../pictures/Griff_Logo_100x100_transparent.png'
import LoginFormb from '../../pages/loginb/loginb';
function HeaderB(props) {
    return (
        <Container>
            <Row>
                <Col md={1}>
                    <Image src={myLogo} rounded />
                </Col>
                <Col md={9} >
                    <div>
                        <h1>GRIFF SPORTEGYESÜLET</h1>
                    </div>
                </Col>
                <Col md={2}>
                    <LoginFormb
                        setLoginState={props.setLoginState}
                        loginstate={props.loginstate}
                        setToken={props.setToken}
                        token={props.token}
                        setUserName={props.setUserName}
                        userName={props.userName}
                        language={props.language}
                        setGendersComboData={props.setGendersComboData}
                        setUnitComboData={props.setUnitComboData}
                        setOrganisationsComboData={props.setOrganisationsComboData}
                        setPersonsComboData={props.setPersonsComboData}
                        setSportAbilitiesComboData={props.setSportAbilitiesComboData}
                        goToHome={props.goToHome}
                    >
                    </LoginFormb>
                </Col>
            </Row>
        </Container>
    )
}

export default HeaderB;