import { useState, Fragment } from 'react';
import './App.scss';
import LoginData from './components/LoginData';
import { BrowserRouter, Routes, Route, Navigate, useNavigate, Link } from 'react-router-dom';
import HeaderB from './components/headerb/headerb';
import MyConnections from './components/connections/connections';
import PageContacts from './pages/contacts/pageContacts';
import HomePage from './pages/home/home';
import PageEvents from './pages/esemenyek/pageEvens';
import GriffNavbar from './components/navbar/griffnavbar';
import PageTrainingGroups from './pages/trainingGroups/pageTrainingGroups';
import Teszt from './pages/pagetest/pageTest';
import PageOrganisations from './pages/organisations/pageOrganisations';
import PagePerons from './pages/persons/pagePersons';
import PageSportAbilities from './pages/sportabilities/pageSportAbilities';
import PageExercise from './pages/exercise/pageExercise';

function App() {
    const [language, setLanguage] = useState('hu');
    const [loginData, setLoginData] = useState(null);
    const [loginState, setLoginState] = useState('LOGOUT')
    const [token, setToken] = useState();
    const [userName, setUserName] = useState();
    const [gendersComboData, setGendersComboData] = useState();
    const [unitComboData, setUnitComboData] = useState();
    const [organisationsComboData, setOrganisationsComboData] = useState();
    const [sportAbilitiesComboData, setSportAbilitiesComboData] = useState();
    const [trainingGroupsComboData, setTrainingGroupsComboData] = useState();
    const [personsComboData, setPersonsComboData] = useState();

    function onLanguageChanged(newLanguage) {
        setLanguage(newLanguage);
    }

    function onLogout() {
        setLoginData(null);
    }

    function onExtendToken(newToken) {
        if (!newToken) {
            onLogout();
            return;
        }
        let newLogin = new LoginData(newToken);
        setLoginData(newLogin);
    }

    function onLogin(newLoginData) {
        setLoginData(newLoginData);
    }

    function setActivity() {
        if (loginData) {
            loginData.setLastActivity();
        }
    }

    function GoToHome() {
        // const navigate = useNavigate();
        // navigate("/home")
    }

    return (
        <div className="App" onMouseDown={setActivity} onKeyDown={setActivity}>
            <header className="App-header">
                <BrowserRouter>
                    <Fragment>
                        <HeaderB
                            setLoginState={setLoginState}
                            loginstate={loginState}
                            setToken={setToken}
                            token={token}
                            setUserName={setUserName}
                            userName={userName}
                            language={language}
                            setGendersComboData={setGendersComboData}
                            setUnitComboData={setUnitComboData}
                            setOrganisationsComboData={setOrganisationsComboData}
                            setPersonsComboData={setPersonsComboData}
                            setSportAbilitiesComboData={setSportAbilitiesComboData}
                            goToHome={GoToHome}
                        >
                        </HeaderB>
                        <GriffNavbar
                            language={language}
                            loginData={loginData}
                            loginstate={loginState}
                        />
                        <div className='App-body'>
                            <Routes>
                                <Route path='/' element={
                                    <HomePage></HomePage>
                                }>
                                </Route>
                                <Route path='/home' element={
                                    <HomePage></HomePage>
                                }>
                                </Route>
                                <Route path='/esemenyek' element={
                                    <PageEvents
                                        loginstate={loginState}
                                        token={token}
                                        userName={userName}
                                        language={language}
                                    ></PageEvents>
                                }>
                                </Route>
                                <Route path='/contacts' element={
                                    <PageContacts
                                        loginstate={loginState}
                                        token={token}
                                        userName={userName}
                                        language={language}
                                    ></PageContacts>
                                }>
                                </Route>
                                <Route path='/tgroups' element={
                                    <PageTrainingGroups
                                        loginstate={loginState}
                                        token={token}
                                        userName={userName}
                                        language={language}
                                        setTrainingGroupsComboData={setTrainingGroupsComboData}
                                        onLogout={onLogout}
                                    ></PageTrainingGroups>
                                }>
                                </Route>
                                <Route path='/exercise' element={
                                    <PageExercise
                                        loginstate={loginState}
                                        token={token}
                                        userName={userName}
                                        language={language}
                                        sportAbilitiesComboData={sportAbilitiesComboData}
                                        unitComboData={unitComboData}
                                    ></PageExercise>
                                }>
                                </Route>
                                <Route path='/organisations' element={
                                    <PageOrganisations
                                        loginstate={loginState}
                                        token={token}
                                        userName={userName}
                                        language={language}
                                        setOrganisationsComboData={setOrganisationsComboData}
                                        onLogout={onLogout}
                                    ></PageOrganisations>
                                }>
                                </Route>
                                <Route path='/persons' element={
                                    <PagePerons
                                        loginstate={loginState}
                                        token={token}
                                        userName={userName}
                                        language={language}
                                        setPersonsComboData={setPersonsComboData}
                                        gendersComboData={gendersComboData}
                                        organisationsComboData={organisationsComboData}
                                        onLogout={onLogout}
                                    ></PagePerons>
                                }>
                                </Route>
                                <Route path='/abilities' element={
                                    <PageSportAbilities
                                        loginstate={loginState}
                                        token={token}
                                        userName={userName}
                                        language={language}
                                        setSportAbilitiesComboData={setSportAbilitiesComboData}
                                        onLogout={onLogout}
                                    ></PageSportAbilities>
                                }>
                                </Route>
                                <Route path='/teszt' element={
                                    <Teszt
                                        loginstate={loginState}
                                        token={token}
                                        userName={userName}
                                        language={language}
                                    ></Teszt>
                                }>
                                </Route>
                            </Routes>
                        </div>
                        <MyConnections></MyConnections>
                    </Fragment>
                </BrowserRouter>
            </header>
        </div>
    );
}

export default App;
