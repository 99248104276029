import React, { useState } from "react";
import { Form } from "react-bootstrap"
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './writeme.scss'
import axios from 'axios';

const EmailURL = `${process.env.REACT_APP_API_BASE_URL}/sendemail`;

function WriteMe() {
    const [guestName, setGuestName] = useState('')
    const [guestEmail, setGuestEmail] = useState('')
    const [guestMessage, setGuestMessage] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        // Ide jön az email küldés
        sendEmail();
    }

    async function sendEmail() {
        let guestMessage2 = guestEmail.concat(" - ", guestMessage)
        try {
            const response = await axios.post(EmailURL, {
                headers: {
                    fromemail: guestEmail,
                    subject: guestName,
                    message: guestMessage2
                }
            }).then((jsonData) => {
            }).catch((err) => {
                console.log('loginb.js (line: 30)', err);
            })
            const result = await response.json();
            console.log(result);
        } catch (error) {
            console.error('Hiba történt az e-mail küldésekor:', error);
        }
    }

    return (
        <Form className="form-write-me">
            <Row className="emptyrow"></Row>
            <Row className="rowhead">
                <h3>Írjon nekünk!</h3>
            </Row>
            <Row className="topbottomright">
                <Col md={2}>
                    <h5>Név:</h5>
                </Col>
                <Col md={4}>
                    <Form.Control
                        type="name"
                        placeholder="Enter name"
                        onChange={(e) => setGuestName(e.target.value)}
                    />
                </Col>
                <Col md={2}>
                    <h5>Email:</h5>
                </Col>
                <Col md={4}>
                    <Form.Control
                        type="email"
                        placeholder="Enter email"
                        onChange={(e) => setGuestEmail(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="bottomright">
                <Col md={2}>
                    <Row>
                        <h5>Üzenet:</h5>
                    </Row>
                    <Row>
                        <Button variant="success" type="button" onClick={sendEmail}>
                            Üzenet küldése
                        </Button>
                    </Row>
                </Col>
                <Col md={10}>
                    <Form.Control
                        type="text"
                        as="textarea"
                        placeholder="write message"
                        onChange={(e) => setGuestMessage(e.target.value)}
                    />
                </Col>
            </Row>
        </Form>
    )
}

export default WriteMe;