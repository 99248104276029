import React from 'react';
import subformicon from "../icons/subform.svg"

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const buttonClicked = () => {
        props.setFormState(props.form);
        // if (props.form === 'Child1') {
        //     props.setFormState('CHILD');
        // }
        // if (props.form === 'Child2') {
        //     props.setFormState('CHILD2');
        // }
    };

    return (
        <span>
            <span>{cellValue}</span>&nbsp;
            <img
                className='subformIcon'
                src={subformicon}
                onClick={() => buttonClicked()}
                alt="pencil"
            />

        </span>
    );
};