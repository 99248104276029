import axios from "axios";
const DataURL = `${process.env.REACT_APP_API_BASE_URL}/data`;

async function ShowData(props) {
    return axios.get(DataURL, {
        headers: {
            'Content-Type': 'application/json',
            'token': props.token,
            'collection': props.collection,
            'userName': props.userName,
            'filter': props.where,
            'language': props.language,
            'Data': props.saveprops,
        }
    })
        .then((retval) => {
            if (retval.status !== 200) {
                const error = new Error('invalid');
                error.status = retval.status;
                throw error;
            }
            const dataFromBackend = retval.data.data;
            return dataFromBackend;
        })
        .then((jsonData) => {
            props.setRowData(jsonData);
        })
        .catch((err) => {
            console.log('gridExercise.js (line: 272)', err);
        });
}

export default ShowData;