import React, { useState } from "react";
import { Form } from "react-bootstrap"
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Placeholder from 'react-bootstrap/Placeholder';
import './loginb.scss'
import axios from 'axios';
import GridComboData from "../../components/gridComboData";
import { comboDefOrganisations } from "../../components/gridcombodefs/gridComboDefOrganisations";
import { comboDefSportAbilities } from "../../components/gridcombodefs/gridComboDefSportAbilities";
import { comboDefGendersProps } from "../../components/gridcombodefs/gridComboDefGenders";
import { comboDefUnitProps } from "../../components/gridcombodefs/gridComboDefUnits";

function LoginFormb(props) {
    const UsersURL = `${process.env.REACT_APP_API_BASE_URL}/token`;

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')

    async function GetToken(credentials) {
        let token;
        axios.get(UsersURL, {
            headers: {
                email: credentials.login,
                password: credentials.password
            }
        }).then((jsonData) => {
            token = jsonData.data.token;
            props.setToken(token);
            props.setUserName(credentials.login)
            setToLoggedIn();
            initCombo(token);
        }).catch((err) => {
            setToLogout();
            console.log('loginb.js (line: 30)', err);
        })
        return token;
    }

    function setEditLogin() {
        props.setLoginState('EDITLOGIN')
    }

    function setToLogout() {
        props.setLoginState('LOGOUT')
        props.setUserName(null)
        props.setToken(null)
        props.goToHome();
    }

    function setToLoggedIn() {
        props.setLoginState('LOGGEDIN')
    }

    function getComboArray(json) {
        let result = [];
        for (var i = 0; i < json.data.data.length; i++) {
            result.push(json.data.data[i].FieldValue)
        }
        return result;
    }

    async function initCombo(token) {
        const comboUnitProps = comboDefUnitProps;
        comboUnitProps.token = token;
        comboUnitProps.language = props.language;

        const comboOrganisationsProps = comboDefOrganisations;
        comboOrganisationsProps.token = token;
        comboOrganisationsProps.language = props.language;

        const comboAbilitiyProps = comboDefSportAbilities;
        comboAbilitiyProps.token = token;
        comboAbilitiyProps.language = props.language;

        const comboGendersProps = comboDefGendersProps
        comboGendersProps.token = token;
        comboGendersProps.language = props.language;

        const comboPersonsProps = {
            token: token,
            collection: 'persons_view_gridCombo',
            select: 'FieldValue',
            language: props.language,
            top: '500',
            where: '',
            groupby: '',
            orderby: 'FieldValue',
            with0: 'true',
        }

        const unitComboData = await GridComboData(comboUnitProps);
        const gendersComboData = await GridComboData(comboGendersProps);
        const organisationsComboData = await GridComboData(comboOrganisationsProps);
        const personsComboData = await GridComboData(comboPersonsProps);
        const sabilityComboData = await GridComboData(comboDefSportAbilities);
        props.setUnitComboData(unitComboData);
        props.setGendersComboData(gendersComboData);
        props.setOrganisationsComboData(organisationsComboData);
        props.setPersonsComboData(personsComboData);
        props.setSportAbilitiesComboData(sabilityComboData);

        // let unitComboArray = getComboArray(unitComboData)
        // let genderComboArray = getComboArray(gendersComboData)
        // let organisationsComboArray = getComboArray(organisationsComboData)
        // let personsComboArray = getComboArray(personsComboData)
        // props.setUnitComboData(unitComboArray);
        // props.setGendersComboData(genderComboArray);
        // props.setOrganisationsComboData(organisationsComboArray);
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        await GetToken({
            login,
            password
        })
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Row className={`row5 ${props.loginstate}`}>
                <Col>
                    <Form.Group className="mb-3" controlId="formGroupPassword">
                        <Button variant="primary" type="button" onClick={setToLogout}>
                            Logout
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
            <Row className={`row1 ${props.loginstate}`}>
                <Col>
                    <Form.Group className="mb-3" controlId="formGroupPassword">
                        <Button variant="primary" type="button" onClick={setEditLogin}>
                            Login
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
            <Row className={`row2 ${props.loginstate}`}>
                <Col>
                    <Form.Group className="mb-3" controlId="formGroupPassword">
                        <Placeholder.Button xs={12} bg="transparent">{props.userName}</Placeholder.Button>
                    </Form.Group>
                </Col>
            </Row>
            <Row className={`row3 ${props.loginstate}`}>
                <Col md={9}>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            onChange={(e) => setLogin(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Button variant="secondary" type="button" onClick={setToLogout}>
                        Cancel
                    </Button>
                </Col>
            </Row>
            <Row className={`row4 ${props.loginstate}`}>
                <Col md={9}>
                    <Form.Group className="mb-3" controlId="formGroupPassword" >
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Button variant="success" type="button" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default LoginFormb;