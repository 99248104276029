function Greating02() {
    return (
        <div className="gretingpage" >
            <br></br>
            <br></br>
            <h3>
                Edzéseink
            </h3>
            <br></br>
            <h4>
                Helyszín:
            </h4>
            Sportcentrum
            Pápa, Téglagyári út 3.
            <br></br>
            <br></br>
            <h4>
                Időpontok:
            </h4>
            Hétfő: 17:00 - 18:30
            <br></br>
            Szerda: 17:00 - 18:30
            <br></br>
            Szombat: 08:15 - 09:15
        </div>

    )
}

export default Greating02;