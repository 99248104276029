import axios from 'axios';
const DataURL = `${process.env.REACT_APP_API_BASE_URL}/data`;

async function SaveData(props) {
    let recID = 0;
    if (props.saveprops.ID) { recID = props.saveprops.ID }
    return axios.put(DataURL, {
        'Content-Type': 'application/json',
        'token': props.token,
        'collection': props.collection,
        'userName': props.userName,
        'ID': recID,
        'Data': props.saveprops,
    })
        .then(async (result) => {
            return result;
        })
        .catch((err) => {
            alert(err.response.data.message);
            console.error(err);
        });
}

export default SaveData;