import { Col, Row } from "react-bootstrap"
import Image from 'react-bootstrap/Image';
import Pict from './pictures/Adam_Barna_01.jpg'
import Pict2 from './pictures/Adam_Barna_02.jpg'
import Pict3 from './pictures/Adam_Barna_03.jpg'
import Pict4 from './pictures/Adam_Barna_04.jpg'
import Pict5 from './pictures/Adam_Barna_05.jpg'
import Pict6 from './pictures/Adam_Barna_06.jpg'
import '../pageEvents.scss'

function Event2025_03_01() {
    return (
        <div className="MsoNormal">
            <Row className="headline">
                <Col md={6}>
                    <h2>Barna öves vizsga, és haladó technikai edzés</h2>
                </Col>
                <Col md={6}>
                    <h2>2025.03.01</h2>
                </Col>
            </Row>
            <br></br>
            <Row md={4}>
                <Col md={3}>
                    <p>
                        <h5>
                            <p>
                                Március első napján délelőtt nagyon sokat karatéztunk 😊
                            </p>
                            <p>
                                Reggel 8.15-től 9.15-ig megtartottuk szokásos szombat reggeli edzésünket, ahol először
                                pontkesztyűn összetett küzdő kombinációkat gyakoroltunk, majd a Karate Diák Olimpiára készülő
                                versenyzőink váltott ellenfeles küzdelmet végeztek.
                            </p>
                        </h5>
                    </p>
                    <Image src={Pict2} fluid />
                    <br></br>
                    <br></br>
                </Col>
                <Col md={1}>
                </Col>
                <Col md={3}>
                    <h5>
                        <br></br>
                        <p className="underline-text">
                            Délelőtt 10-től azonban egy másik "műfajban" csiszoltuk tudásunkat.
                        </p>
                        <br></br>
                        <p>
                            Eljött az idő, hogy régi klub- és harcos társunk, Petrovics Ádám megmérettesse magát 2. kyu övfokozatra.
                        </p>
                        <br></br>
                        <p>
                            A karatéban megkülönböztetünk tanuló (kyu) és mester (dan) övfokozatokat.
                            A kyu övfokozatok 10-től kezdődnek, egy új karatés tehát erre vizsgázik először. A második vizsga a 9. kyu, a harmadik pedig a 8. kyu, stb...
                            A vizsgákra kivárási idő is van, tehát eltelik jó néhány év, mire a tanítvány a 2. kyus vizsgáig eljut.
                            Aki ezt a fokozatot sikeresen teljesíti, barna övet köthet a derekára.
                            Ez pedig a mester fokozat előtti utolsó öv szín, ami komoly tudást igényel, és komoly felelősséggel jár.
                        </p>
                        <br></br>
                        <p>
                            A két órás megmérettetést Ádám azokkal a klubtársaival teljesítette, akik korábban már levizsgáztak barna övre, vagy azóta már
                            tovább is jutottak a karatés ranglétrán.
                        </p>
                        <br></br>
                        <p>
                            Ádám az elmúlt években az egyik legaktívabb tagnak bizonyult a sportegyesületben, és sok esetben az
                            edzések tartásában is komoly segítséget jelent. A barna övre külön edzéseken is készült, ahol technikai
                            és kondicionális felkészültségét már többször bizonyította. A mai nap már csak egy pont volt az i betűre.
                        </p>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <p>
                            Gratulálunk Ádámnak a komoly fejlődéshez, és a vizsgán a nehezebb helyzetekben is tanúsított kitartáshoz!
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            Jó, hogy köztünk vagy, OSU!
                        </p>
                        <Image src={Pict6} fluid />
                    </h5>
                </Col>
                <Col md={4}>
                    <Image src={Pict} fluid />
                    <br></br>
                    <br></br>
                    <Row>
                        <Col md={4}>
                            <Image src={Pict3} fluid />
                        </Col>
                        <Col md={4}>
                            <Image src={Pict4} fluid />
                        </Col>
                        <Col md={4}>
                            <Image src={Pict5} fluid />
                        </Col>
                    </Row>
                    <br></br>
                    <br></br>
                </Col>
            </Row>
            <Row>
                <h3>
                    <p>Fotó: Baloghné Farkas Katalin</p>
                </h3>
            </Row>
        </div>
    )

}
export default Event2025_03_01