import { Col, Row } from "react-bootstrap"
import Image from 'react-bootstrap/Image';
import Pict1 from './pictures/Vizsga_01.jpg'
import Pict2 from './pictures/Vizsga_02.jpg'
import Pict3 from './pictures/Vizsga_03.jpg'
import Pict4 from './pictures/Vizsga_04.jpg'
import Pict5 from './pictures/Vizsga_05.jpg'
import Pict6 from './pictures/Vizsga_06.jpg'
import Pict7 from './pictures/Vizsga_07.jpg'
import '../pageEvents.scss'

function Event2025_03_03() {
    return (
        <div className="MsoNormal">
            <Row className="headline">
                <Col md={6}>
                    <h2>Öv vizsga a Sportcentrumban</h2>
                </Col>
                <Col md={6}>
                    <h2>2025.03.03</h2>
                </Col>
            </Row>
            <br></br>
            <Row md={4}>
                <Col md={4}>
                    <p>
                        <h5>
                            <p>
                                Az elmúlt hónapok a verseny felkészülések mellett öv vizsgára való készüléssel is telltek a Griff Sportegyesület Kyokushin Karate Szakosztályában.
                            </p>
                        </h5>
                    </p>
                    <Image src={Pict2} fluid />
                    <br></br>
                    <br></br>
                    <Image src={Pict6} fluid />
                    <br></br>
                    <br></br>
                    <Image src={Pict7} fluid />
                    <br></br>
                </Col>
                {/* <Col md={1}>
                </Col> */}
                <Col md={4}>
                    <h5>
                        <br></br>
                        <p>
                            Egyesületünkben évente általában 2 vizsgát tartunk. Egyet a nyári edzőtáborunkban, és egyet kora tavasszal
                            még a verseny szezon indulása előtt. 2025-ben a tavaszi vizsga időpontja március 3-ra esett.
                        </p>
                        <p>
                            A vizsgákat alapos felkészülés előzi meg. Egy-egy vizsgára minimum 4-6 hónap felkészülési idő jut az előző
                            sikeres vizsgától számítva. Magasabb fokozatokra ez 1 év, még magasabb, mester fokozatokra, 3-5 év is lehet.
                        </p>
                        <br></br>
                        <p>
                            A felkészülés során meg kell ismerni azt a technikai anyagot, ami a kyokushin karatéban nagy részben az egész világon ugyanaz.
                            Ismerni kell a technikák nevét, végrehajtani állásban, haladás közben, és tudásszintnek megfelelően ezeket
                            össze is kell tudni kombinálni egymással. Ezek a technikai elemek különböző állásokból, ütésekből, rúgásokból, védésekből és formagyakorlatokból állnak.
                            A magasabb övfokozatra vizsgázónak ismernie kell az alacsonyabb technikai anyagot is, amelyből már megérettette magát,
                            de még pontosabban, még magasabb szinten, mint korábban.
                        </p>
                        <br></br>
                        <p>
                            A vizsgának azonban a technikai mellett, kondicionális elemei is vannak. Ezek általános erőállóképességi feladatok, mint fekvőtámasz, guggolás,
                            felülés, homorítás. A vizsgázóknak ezt is be kell mutatnia, mert a megfelelő erőnlét elengedhetetlen része a mi harcművészetünknek. Mind e mellett
                            már köztudott, hogy a megfelelő szinten tartott izomzat, és állóképesség milyen pozitív hatással van az egyén egészségére, életminőségére.
                        </p>
                        <br></br>
                        <p>
                            Végül, de nem utolsó sorban a vizsgázónak szabad küzdelemben is bizonyítania kell képességeit. Övfokozattól és kortól függően határozzuk meg, hány küzdelemben
                            kell részt venniük. Az egyenként 1,5 perces küzdelmeket szünet nélkül kell teljesíteni, mindig új ellenfelekkel, akik a klub éppen nem vizsgázó tagjaiból kerülnek ki.
                            A segítőnek itt az a dolga, hogy a lehető legtöbbet kihozza a vizsgázóból.
                        </p>
                        <br></br>
                        <p>
                            Összefoglalva: a kyokushin karate vizsga egy megmérettetés, a karate útján tett fejlődés egyértelmű indikátora, nagyszerű lehetőség a fejlődésre,
                            motivációs tényező, amely újabb és újabb kihívásokkal állítja szembe a gyakorlót.
                        </p>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <p>
                            Március 3-án a Griff Sportegyesület karatésai közül 15-en álltak tatamira, és teljesítették az övfokozatuknak megfelelő követelményeket.

                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            Gratulálunk minden vizsgázónak!
                        </p>
                        <Image src={Pict4} fluid />
                    </h5>
                </Col>
                <Col md={4}>
                    <Image src={Pict1} fluid />
                    <br></br>
                    <br></br>
                    <Image src={Pict3} fluid />
                    <br></br>
                    <br></br>
                    <Image src={Pict5} fluid />
                </Col>
            </Row>
            <Row>
                <h3>
                    <p>Fotó: Baloghné Farkas Katalin</p>
                </h3>
            </Row>
        </div>
    )

}
export default Event2025_03_03