import './greating.scss'

function Greating() {
    return (
        <div className='greatingpage'>
            <br></br>
            <h2>Üdvözlöm a Griff Sportegyesület oldalán!</h2>
            <br></br>
            <h3>Röviden rólunk</h3>
            <p className="MsoNormal">Kyokushin karate edzések 42 évvel
                ezelőtt, 1982 október 15-én indultak városunkban. Jelenlegi formában, a
                Griff Sportegyesület szakosztályaként 1993-óta m&#369;ködik. Edzéseinket 2021
                óta a Sport Centrum tornatermében tartjuk. Tagjaink között a 6 éves
                gyermekt&#337;l az 50 év feletti feln&#337;ttig minden korosztály megtalálható.</p>

            <p className="MsoNormal">Programunk a kyokushin karate,
                mint japán harcm&#369;vészeti stílus mozgás anyagára épül, a résztvev&#337;k
                életkori sajátosságainak megfelel&#337;en. Ritka az olyan sport foglalkozás,
                ahol szül&#337; és gyermek együtt tud dolgozni, fejl&#337;dni. Nálunk ez lehetséges,
                és az elmúlt években sokan éltek is ezzel a lehet&#337;séggel. </p>

            <p className="MsoNormal">A karatét els&#337; sorban nem versenysportként
                gyakoroljuk, de a verseny felkészítés is fontos helyet kap programunkban. Azokkal,
                akik ezt igénylik, és vállalják, tapasztalt edz&#337;k irányításával készülünk
                területi, országos vagy akár nemzetközi szint&#369; versenyekre. Kyokushin
                Karate Diák Olimpiai, Magyar bajnoki, valamint Európa Bajnoki címmel is
                büszkélkedhetünk. Jelenleg is van olyan aktív tagunk, aki feln&#337;tt, vagy
                junior EB-re készül.</p>

            <p className="MsoNormal">Az elmúlt évtizedek során a Griff
                Sportegyesület számos fekete öves mestert nevelt ki. Az egyik legfontosabb mérföldk&#337;
                egy harcm&#369;vész életében, amikor a mesteri szintet eléri. A mesteri
                fokozatig 8-10 éves, folyamatos, kitartó, fegyelmezett munkára van szükség a
                kyokushin karatéban. A befektetett munka azonban hatványozottan megtérül, és a jól
                felkészült tanítvány nem csak a karate, hanem az élet mesterévé is válik.</p>
        </div>
    )
}

export default Greating;