import Image from 'react-bootstrap/Image';
import pict from '../../pictures/Gyerek_csapat3_2.png'

function Car04() {
    return (
        <div>
            <Image src={pict} fluid />
        </div>
    )
}

export default Car04;