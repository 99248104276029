import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Car01 from './caro01';
import Car02 from './caro02';
import Car03 from './caro03';
import Car04 from './caro04';

function CarouselB() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
                <Car01 text="Mozgáskultúra" />
                <Carousel.Caption>
                    {/* <h3>1</h3> */}
                    {/* <p>Griff Sportegyesület</p> */}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <Car02 text="Second slide" />
                <Carousel.Caption>
                    {/* <h3>2</h3> */}
                    {/* <p>Bóka Viktória - Göteborg EB 2023</p> */}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <Car03 text="Third slide" />
                <Carousel.Caption>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <Car04 text="slide4" />
                <Carousel.Caption>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default CarouselB;