/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import saveRenderer2 from '../../components/renderers/saveRenderer2';
import { validators } from '../../components/validators/validators';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import ShowData from '../../components/datahandling/showdata';
import SaveData from '../../components/datahandling/savedata';
import Ajv from 'ajv';
import { ajvSchemaResToEx, createNewRowDataResToEx } from './gridDef';
import GridForm from "../../components/gridform/gridForm";
import { Button, Row } from 'react-bootstrap';
import DelRow2 from '../../components/datahandling/deletedata';
const myAjv = new Ajv()

function GridResToEx(props) {
    const gridRef = useRef(); // Optional - for accessing Grid's API
    const myCollection = 'vResToEx'
    const gridId = 'GRID2'
    const [editingRowId, setEditingRowId] = useState(null); // A grid sorban szerkesztéskor a save gomb megjelenítéséhez kell.

    const parentIDRef = useRef(null);
    const parentNameRef = useRef(null);

    useEffect(() => {
        if (props.currDataRow) {
            parentIDRef.current = props.currDataRow.ID;
            parentNameRef.current = props.currDataRow.ExerciseName;
        }
    }, [props.currDataRow]);

    useEffect(() => {
        stateChangeHandling();
    }, [props.formState, props.currDataRow])
    // }, [props.formState], [props.currDataRow])

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
    }));

    const columnDefs = useMemo(() => [
        {
            field: 'ID',
            hide: true,
        },
        {
            field: 'ResultTypeDescription',
            headerName: 'Leírás',
            cellStyle: params => {
                const invalid = validators.validate('required', params.value, props.language)
                if (invalid) {
                    return { backgroundColor: '#6e100a' };
                }
                return null;
            },
            filter: true,
            width: 400,
            editable: true
        },
        {
            field: 'UnitName',
            headerName: 'Mértékegység',
            cellEditor: 'agSelectCellEditor',
            editable: true,
            cellStyle: params => {
                const invalid = validators.validate('required', params.value, props.language)
                if (invalid) {
                    return { backgroundColor: '#6e100a' };
                }
                return null;
            },
            width: 180,
            cellEditorParams: {
                values: props.unitComboData
            },

        },
        {
            field: 'btsave',
            width: 50,
            headerName: '💾',
            cellRenderer: saveRenderer2,
            valueGetter: () => "", // 🔄 Üres érték, de a renderer meghívódik
        },
    ], [editingRowId]);

    const getRowId = useCallback(function (params) {
        return params.data.ID;
    }, []);

    function addItem() {
        const newItems = [
            createNewRowDataResToEx()
        ];
        const res = gridRef.current.api.applyTransaction({
            add: newItems,
            addIndex: 0,
        });
        return res;
    };

    const handleStopEditing = () => {
        if (gridRef.current) {
            const api = gridRef.current.api;
            api.stopEditing(true); // Elveti a módosításokat
            // vagy: api.stopEditing(false); // Megtartja a módosításokat
        }
    };

    async function stateChangeHandling() {
        switch (props.formState) {
            case 'GRID2NEW':
                addItem();
                break;
            case 'GRID2CANCELNEW':
                handleStopEditing();
                ShowMyData();
                props.setFormState(gridId)
                break;
            case 'GRID2DEL2':
                await DeleteMyData();
                await ShowMyData();
                await ShowParentData();
                props.setFormState(gridId)
                break;
            default:
        }
    }

    async function DeleteMyData() {
        return await DelRow2({
            gridRef: gridRef,
            token: props.token,
            collection: myCollection,
            userName: props.userName,
        }).then(async (result) => {
            return result;
        }).catch((err) => {
            console.error(err);
        })
    }

    async function setParentData(params) {
        if (params) {
            await ShowMyData();
        }
    }

    async function ShowMyData() {
        let mywhere = `ExerciseID = ${parentIDRef.current}`;
        await ShowData({
            token: props.token,
            setRowData: props.setResToExRowData,
            language: 'HU',
            userName: props.userName,
            collection: myCollection,
            where: mywhere,
        });
    }

    async function ShowParentData() {
        await ShowData({
            token: props.token,
            setRowData: props.setExerciseRowData,
            language: 'HU',
            userName: props.userName,
            collection: 'vExercise',
            where: '',
        });
    }

    function CloseMe() {
        props.setFormState('HBASE')
    }

    props.biRef.SetParent_grid2 = setParentData;
    const gridContext = useMemo(() => ({ editingRowId }), [editingRowId]);

    const onRowEditingStarted = (params) => {
        if (props.formState === gridId) {
            props.setFormState(`${gridId}EDIT`)
        }
        setEditingRowId(params.data.ID); // 🔥 Állapot frissítése

        setTimeout(() => {
            if (gridRef.current && gridRef.current.api) {
                gridRef.current.api.refreshCells({
                    rowNodes: [params.node], // 🔥 Pontosan ezt a sort frissítsük!
                    columns: ["btsave"]
                });
            } else {
                console.warn("⚠️ Grid API nincs elérhető!");
            }
        }, 50);
    };

    function fieldValid(saveprops) {
        let result = true;
        const validate = myAjv.compile(ajvSchemaResToEx);
        result = validate(saveprops)
        return result;
    }

    async function SaveMyData(saveprops) {
        await SaveData({
            token: props.token,
            setRowData: props.setResToExRowData,
            language: 'HU',
            userName: props.userName,
            collection: myCollection,
            saveprops: saveprops
        })
            .then(async (result) => {
                await ShowMyData();
                await ShowParentData();
                props.setFormState(gridId)
                return;
            })
            .catch((err) => {
                alert(err.response.data.message);
                console.error(err);
            });
    }

    const onRowValueChanged = useCallback(async (event) => {
        let savedata = event.data;
        savedata.ExerciseID = parentIDRef.current;
        const valid = fieldValid(savedata)
        if (valid) {
            await SaveMyData(savedata)
        }
        else {
            console.log('!!!SSS gridResToEx.js (line: 218) Hiányos adat');
        }
    }, []);

    return (
        <div>
            <GridForm
                setFormState={props.setFormState}
                formState={props.formState}
                gridId={gridId}
            >
            </GridForm>
            <h4>{parentNameRef.current} (Várt eredmények)</h4>
            <div className="gridpage">
                <div className="ag-theme-alpine-dark" style={{ width: '100%', height: '300pt' }}>
                    <AgGridReact ref={gridRef}
                        context={gridContext}  // 🔥 Memozott context
                        rowData={props.resToExRowData}
                        columnDefs={columnDefs}
                        getRowId={getRowId}
                        defaultColDef={defaultColDef}
                        editType={'fullRow'}
                        onRowValueChanged={onRowValueChanged}
                        onRowEditingStarted={onRowEditingStarted}
                        rowSelection='multiple'>
                    </AgGridReact>
                </div>
            </div>
            <Row>
                <Button
                    onClick={CloseMe}
                >
                    BEZÁR
                </Button>
            </Row>

        </div>
    )
}

export default GridResToEx;