export const ajvSchemaExercise = {
    type: "object",
    properties: {
        ID: { type: "integer" },
        ExerciseName: { type: "string", minLength: 1 },
        ExerciseDescription: {},
        SportAbilityName: { type: "string", minLength: 1 },
        SportAbilitiesID: {},
        ResToExString: {},
    },
    required: [
        "ExerciseName",
        "SportAbilityName",
    ],
    additionalProperties: false,
}

export const ajvSchemaResToEx = {
    type: "object",
    properties: {
        ID: { type: "integer" },
        ExerciseID: { type: "integer" },
        UnitTypesID: {},
        ResultTypeDescription: { type: "string", minLength: 1 },
        UnitName: { type: "string", minLength: 1 },
        ExerciseName: {},
    },
    required: [
        "UnitName",
        "ResultTypeDescription",
        "ExerciseID"
    ],
    additionalProperties: false,
}

export function createNewRowDataExercise() {
    const newData = {
        ID: 0,
        ExerciseName: "",
        ExerciseDescription: "",
        SportAbilityName: "---",
        ResToExString: ""
    };
    return newData;
}

export function createNewRowDataResToEx() {
    const newData = {
        ID: 0,
        ResultTypeDescription: "",
        UnitName: "---",
    };
    return newData;
}

export const formFieldsExercise = [
    {
        field: "ExerciseDescription",
        type: "string"
    }
]

