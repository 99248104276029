import { useState, useEffect } from "react";
import GridExercise from "./gridExercise";
import GridForm from "../../components/gridform/gridForm";
import FormExercise from "./formExercise";
import GridResToEx from "./gridResToEx";
import './exercise.scss'
function PageExercise(props) {
    const [formState, setFormState] = useState('HBASE')
    const [currDataRow, setCurrDataRow] = useState();
    const [exerciseRowData, setExerciseRowData] = useState(null);
    const [resToExRowData, setResToExRowData] = useState(null);
    var biRef = {}

    useEffect(() => {
        biRef.SetFormFields_f(currDataRow);
        biRef.SetParent_grid2(currDataRow);
    }, [currDataRow]);

    return (
        <div>
            <div className={`grid1 ${formState}`}>
                <GridForm
                    setFormState={setFormState}
                    formState={formState}
                    gridId=''
                >
                </GridForm>
                <h2>Gyakorlatok 2</h2>
                <GridExercise
                    setFormState={setFormState}
                    formState={formState}
                    token={props.token}
                    sportAbilitiesComboData={props.sportAbilitiesComboData}
                    biRef={biRef}
                    setCurrDataRow={setCurrDataRow}
                    exerciseRowData={exerciseRowData}
                    setExerciseRowData={setExerciseRowData}
                    >
                </GridExercise>
            </div>
            <div className={`form1 ${formState}`}>
                <FormExercise
                    setFormState={setFormState}
                    biRef={biRef}
                    token={props.token}
                    setExerciseRowData={setExerciseRowData}
                    >
                </FormExercise>
            </div>
            <div className={`grid2 ${formState}`}>
                <GridResToEx
                    token={props.token}
                    setFormState={setFormState}
                    formState={formState}
                    biRef={biRef}
                    unitComboData={props.unitComboData}
                    currDataRow={currDataRow}
                    resToExRowData={resToExRowData}
                    setResToExRowData={setResToExRowData}
                    exerciseRowData={exerciseRowData}
                    setExerciseRowData={setExerciseRowData}
                >
                </GridResToEx>
            </div>
        </div>
    )

}

export default PageExercise;