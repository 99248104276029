import { Row, Col } from 'react-bootstrap';
import './connections.scss'
function MyConnections() {

    return (
        <div>
            <Row className='contactrow'>
                <h4>Kapcsolat, elérhetőségeink</h4>
                <Col md={4}>
                    <p>
                        Telefon: +36 70 5511440
                    </p>
                </Col>
                <Col md={4}>
                    <p>
                        Email: griffse82@gmail.com
                    </p>
                </Col>
                <Col md={4}>
                    <p>
                        Web: www.griffse.hu
                    </p>
                </Col>
            </Row>
        </div>
    )
}

export default MyConnections;