import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState } from 'react';
import { Container } from 'react-bootstrap';
import WriteMe from '../writeme/pageWriteMe';
import Image from 'react-bootstrap/Image';
import SpMap from '../../pictures/sportcentrumterkep.png'
import CarouselB from '../../components/carouselb/carouselb';
import './contacts.scss'

function PageContacts() {

    const [guestEmail, setGuestEmail] = useState('')

    return (
        <Container id='contact-container'>
            <Row>
                <Col md={6}>
                    <Row id='contact-header'>
                        <h3>KAPCSOLAT</h3>
                    </Row>
                    <Row>
                        <Col className='topbottomright' md={4}>
                            <h5>Egyesületi elnök / Klubvezető:</h5>
                        </Col>
                        <Col className='topbottomright' md={8}>
                            <Row id='r2c2r1'>
                                <Col><h5>Név:</h5></Col>
                                <Col><h5>Szalóki Sándor</h5></Col>
                            </Row>
                            <Row id='r2c2r2'>
                                <Col><h5>Telefon:</h5></Col>
                                <Col><h5>+36 (70) 55 11 440</h5></Col>
                            </Row>
                            <Row id='r2c2r3'>
                                <Col><h5>Email:</h5></Col>
                                <Col><h5>griffse82@gmail.com</h5></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='bottomright' md={4}>
                            <h5>Edzések</h5>
                        </Col>
                        <Col className='bottomright' md={8}>
                            <Row>
                                <Col><h5>Helyszín</h5></Col>
                                <Col>
                                    <Row><h5>Pápai Sport Centrum</h5></Row>
                                    <Row id="teglagyari"><h5>Pápa, Téglagyári út 3.</h5></Row>
                                </Col>
                            </Row>
                            <Row id='mapimagerow'><Image src={SpMap} rounded /></Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='bottomright' md={4}><h5>Időpont</h5></Col>
                        <Col className='bottomright' md={8}>
                            <Row>
                                <Col><h5>Hétfő:</h5></Col>
                                <Col><h5>17:00 - 18:30</h5></Col>
                            </Row>
                            <Row>
                                <Col><h5>Szerda:</h5></Col>
                                <Col><h5>17:00 - 18:30</h5></Col>
                            </Row>
                            <Row>
                                <Col><h5>Szombat:</h5></Col>
                                <Col><h5>08:15 - 09:15</h5></Col>
                            </Row>
                        </Col>
                    </Row>
                    <WriteMe></WriteMe>
                </Col>
                <Col md={6}>
                    <CarouselB></CarouselB>
                </Col>
            </Row>
        </Container>
    )
}
export default PageContacts;