import { Col, Row } from "react-bootstrap"
import Image from 'react-bootstrap/Image';
import Pict from './pictures/Revfulop_2023_08.jpg'
import Tibi01 from './pictures/Tibi_Szakdolgozat01.png'
import '../pageEvents.scss'

function Event2025_02_21() {
    return (
        <div className="MsoNormal">
            <Row className="headline">
                <Col md={6}>
                    <h2>Új szakképzett edző a Griff SE soraiban</h2>
                </Col>
                <Col md={6}>
                    <h2>2025.02.21</h2>
                </Col>
            </Row>
            <br></br>
            <Row md={4}>
                <Col md={3}>
                    <p>
                        <h5>
                            Egyesületünk egyik oszlops tagja, fekete öves mestere, Mógor Tibor másfél év intenzív felkészülés, tanulás és aktív segédoktatói tevékenység mellett
                            sikeresen elvégezte a Győri Szakképzési Centrum Sport és Kreatív Technikum képzését, és ezzel karate sportágban középfokú Sportedző, Sportszervező
                            képesítést nyert.
                        </h5>
                    </p>
                    <Image src={Tibi01} fluid />
                    <br></br>
                    <br></br>
                    <h5>
                        <p>
                            Hozzállássa a karatéhoz, a közösségi munkához példa értékű. Elmondhatjuk, hogy személye az egyesület egyik hajtó motorjává vált, nem csak szakmai, de emberi szempontból is.
                        </p>
                    </h5>
                </Col>
                <Col md={1}>
                </Col>
                <Col md={3}>
                    <h5>
                        <br></br>
                        <p>
                            Egy rövid idézet Tibi edzői vizsgára beadott dolgozatából.
                        </p>
                        <br></br>
                        <br></br>
                        <br></br>
                        <p className="italic-text">
                            "...
                            A felkészülés során rengeteg jó dolog történik. Jó instrukciókkal fejlődünk, mélyül a
                            tudás, javul a mozgás szépsége, életre szóló barátságok is születnek a közös holtpont
                            keresés és megtalálása közben... Ezek után még szívesebben foglalkoztunk a karatéval.
                            Megszületett bennem egy gondolat. Zöld öves társaimmal együtt haladtunk, mint „kis
                            virágok”. De közülük én vizsgáztam először barna övre sikeresen. Talán nem is egy
                            „virág” vagyok. Fejlődünk, növünk együtt, de én tovább nőttem, máshogy fejlődök. Talán
                            egy „fa” leszek. Növök tovább és árnyékot adok a többi „kis virágnak”, hogy
                            fejlődhessenek.
                        </p>
                        <p className="italic-text">
                            Egy edző lakik bennem! ..."
                        </p>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <p>
                            Kedves Tibi!
                        </p>
                        <p>
                            Kívánjuk, hogy minél nagyobb fa nőjék belőled!
                        </p>
                    </h5>
                </Col>
                <Col md={4}>
                    <Image src={Pict} fluid />
                    <br></br>
                    <br></br>
                </Col>
            </Row>
        </div>
    )

}
export default Event2025_02_21