import { useState } from "react";
import GridForm from "../../components/gridform/gridForm";
import GridTrainingGroups from "./gridTrainingGroups";
function PageTrainingGroups(props) {
    const [formState, setFormState] = useState('HBASE')
    return (
        <div>
            <GridForm
                setFormState={setFormState}
                formState={formState}
            >
            </GridForm>
            <h4>Csoportok</h4>
            <GridTrainingGroups
                setFormState={setFormState}
                formState={formState}
                token={props.token}
                userName={props.userName}
                setTrainingGroupsComboData={props.setTrainingGroupsComboData}
            >
            </GridTrainingGroups>
        </div>

    )
}
export default PageTrainingGroups;